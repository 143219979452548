@keyframes prixClipFix {
  0% {
    clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
  }
  25% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
  }
  50% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
  }
  75% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
  }
  100% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
  }
}

.animate-prix-clip-fix {
    &::before {
        animation-name: prixClipFix;
        animation-duration: var(--animate-prix-clip-fix-duration, 2s);
        animation-timing-function: var(--animate-prix-clip-fix-timing, linear);
        animation-iteration-count: var(--animate-prix-clip-fix-iteration-count, infinite);
    }
}