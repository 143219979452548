@import '@/styles/base';

.error-page {
  background-color: theme(neutral, 2);
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  height: 100dvh;
  align-content: center;
  justify-items: center;
}
