@keyframes rotate360 {
  100% {
    transform: rotate(360deg);
  }
}

.animate-rotate {
    animation-name: rotate360;
    animation-duration: var(--animate-rotate-duration, 1s);
    animation-timing-function: var(--animate-rotate-timing, linear);
    animation-iteration-count: var(--animate-rotate-iteration-count, infinite);
}