.BackButton_object-fit-contain__knpFV{object-fit:contain}.BackButton_object-fit-cover__Rtriq{object-fit:cover}.BackButton_object-position-center-top__Pu1hI{object-position:center top}.BackButton_object-position-center-bottom__aR57g{object-position:center bottom}.BackButton_object-position-dynamic__oT4GQ{object-position:var(--object-position-horizontal, center) var(--object-position-vertical, center)}.BackButton_button__g1Xue{padding:.625rem 1rem !important}.BackButton_button__g1Xue span{gap:.5rem}
@keyframes prix-clip-fix_prixClipFix__ii2fQ{0%{-webkit-clip-path:polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);clip-path:polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0)}25%{-webkit-clip-path:polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);clip-path:polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0)}50%{-webkit-clip-path:polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);clip-path:polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%)}75%{-webkit-clip-path:polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);clip-path:polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%)}100%{-webkit-clip-path:polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);clip-path:polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0)}}.prix-clip-fix_animate-prix-clip-fix__06CN6::before{animation-name:prix-clip-fix_prixClipFix__ii2fQ;animation-duration:var(--animate-prix-clip-fix-duration, 2s);animation-timing-function:var(--animate-prix-clip-fix-timing, linear);animation-iteration-count:var(--animate-prix-clip-fix-iteration-count, infinite)}
@keyframes rotate_rotate360__qO2su{100%{transform:rotate(360deg)}}.rotate_animate-rotate__ZjoWE{animation-name:rotate_rotate360__qO2su;animation-duration:var(--animate-rotate-duration, 1s);animation-timing-function:var(--animate-rotate-timing, linear);animation-iteration-count:var(--animate-rotate-iteration-count, infinite)}
.object-fit-contain{object-fit:contain}.object-fit-cover{object-fit:cover}.object-position-center-top{object-position:center top}.object-position-center-bottom{object-position:center bottom}.object-position-dynamic{object-position:var(--object-position-horizontal, center) var(--object-position-vertical, center)}.error-page{background-color:#121217;display:grid;grid-template-columns:1fr;grid-template-rows:1fr;height:100dvh;align-content:center;justify-items:center}
/* vietnamese */
@font-face {
  font-family: '__Lexend_866216';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/0cf9d4d37ae83cc2-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Lexend_866216';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/f52011d269d8d5ac-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Lexend_866216';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/f963670c6017484a-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Lexend_Fallback_866216';src: local("Arial");ascent-override: 90.98%;descent-override: 22.74%;line-gap-override: 0.00%;size-adjust: 109.91%
}.__className_866216 {font-family: '__Lexend_866216', '__Lexend_Fallback_866216';font-style: normal
}.__variable_866216 {--font-lexend: '__Lexend_866216', '__Lexend_Fallback_866216'
}

@font-face {
font-family: '__integralCF_fd52d0';
src: url(/_next/static/media/1d8e2840ad9b4f1c-s.p.otf) format('opentype');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: '__integralCF_fd52d0';
src: url(/_next/static/media/d1ae4b1f0463ad25-s.p.otf) format('opentype');
font-display: swap;
font-weight: 400;
font-style: normal;
}@font-face {font-family: '__integralCF_Fallback_fd52d0';src: local("Arial");ascent-override: 75.59%;descent-override: 8.79%;line-gap-override: 0.00%;size-adjust: 142.21%
}.__className_fd52d0 {font-family: '__integralCF_fd52d0', '__integralCF_Fallback_fd52d0'
}.__variable_fd52d0 {--font-integral-cf: '__integralCF_fd52d0', '__integralCF_Fallback_fd52d0'
}

