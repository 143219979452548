.object-fit-contain {
  object-fit: contain;
}
.object-fit-cover {
  object-fit: cover;
}

.object-position-center-top {
  object-position: center top;
}

.object-position-center-bottom {
  object-position: center bottom;
}

.object-position-dynamic {
  object-position: var(--object-position-horizontal, center)
    var(--object-position-vertical, center);
}
